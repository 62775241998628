/* eslint-disable */
import React, {useState} from 'react'
import styled from '@emotion/styled'
import { Formik } from 'formik';
import {injectIntl, FormattedMessage} from 'react-intl';
import fetch from 'isomorphic-unfetch';
import Button from '../../common/Button'
import sendEvent from '../../../helpers/sendAnalytics'
import PetsImage from '../../../assets/images/pets-signup@2x.jpg'

const PetsWrapper = styled.div`
  display: flex;
  width: 95%;
  max-width: 1500px;
  margin: 100px auto 0;
  background: #4D8DCD;
  align-items: stretch;
  font-family: 'Montserrat',sans-serif;
  color: white;
  line-height: 26pt;
  box-shadow: 0px 20px 40px rgba(0,0,0,0.3);
  img {
    object-fit: cover;
  }
  h3 {
    font-size: 26pt;
    line-height: 32pt;
    font-weight: 500;
  }
  > div {
    height: 100%;
    :last-child {
      width: 58%;
      padding: 50px;
    }
  }
  @media (max-width: 919px) {
    flex-direction: column;
    > div {
      height: 100%;
      :last-child {
        width: 100%;
        padding: 20px;
      }
    }
  }
`

const FooterFormWrapper = styled.div`
  ${tw`flex flex-wrap`}
  > div {
    label {
      display: block;
      color: white;
      span {
        display: block;
      }
    }
    input {
      padding: 10px;
      border: 0;
      margin: 0 0 25px;

    }
    input[type=text],input[type=email]{
      width: 95%;
    }
    width: 33.3%;
  }
  > .long {
    width: 32%;
    margin: 0 10px 0 0px;
    label {
      cursor: pointer;
      background: white;
      padding-left: 15px;
      padding-top: 2px;
      color: black;
      height: 42px;
      margin-bottom: 20px;
      input {
        margin-right: 10px;
      }
      span {
        display: inline-block;
      }
      border: 2px solid white;
    }
    .border {
      border: 2px solid #2E3192;
    }
  }
  @media (max-width: 1550px) {
    > .long {
      width: 31.5%;
    }
  }
  @media (max-width: 1350px) {
    > .long {
      width: 31%;
    }
  }
  @media (max-width: 1100px) {
    > .long {
      width: 30.5%;
    }
  }
  @media (max-width: 919px) {
    flex-direction: column;
    > div {
      width: 100%;
      label {
       input[type=text], input[type=email] {
         width: 100%;
       }
      }
    }
    > .long {
      width: 55%;
    }
  }
`


const FooterFormFull = styled.div`
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
`

const FooterFormCheckbox = styled.input`
`

const FooterFormCheckboxLabel = styled.label`
  ${tw`font-raleway text-xs text-white`}
  margin-left: 10px;
  line-height: 8pt !important;
`

const Label = styled.label`
  ${tw`font-raleway text-base text-black block w-full`}

  input {
    margin-top: 10px;
  }
`

const FormButtonWrapper = styled.div`
  ${tw`mt-5`}
  button {
    background: white;
    color: #4d8dcd;

  }
`

const ThanksMessage = styled.div`
  ${tw`font-raleway text-xs text-black leading-tight mt-5`}
`

// we need to turn this back on
 const graphQlServer = 'https://api.royalepromotions.ca/graphql'

const PetsSignUpBlock = ({locale}) => {

  const [animal, setAnimal] = useState('dog')
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  if (submitted) {
    return (
        <PetsWrapper>
          <h2 style={{'text-align':'center',width:'100%',margin: '100px 0'}}>
            <FormattedMessage id="thank_you_subscribe" />
          </h2>
          </PetsWrapper>)
  }

  return (
    <PetsWrapper>
      <img src={PetsImage} />
      <div>
        <FormattedMessage id='petsmail.join'>
          {(txt) => (
            <h3 dangerouslySetInnerHTML={{__html:txt}} />
          )}
        </FormattedMessage>
        <p><FormattedMessage id='petsmail.intro1' /></p>
          <Formik
            initialValues={{ firstname: '', lastname: '', email: '', animal: animal }}
            validate={values => {
              const errors = {}

              if (!values.firstname) {
                errors.firstname = 'Required'
              }

              if (!values.lastname) {
                errors.lastname = 'Required'
              }

              if (!values.email) {
                errors.email = 'Required'
              }

              return errors
            }}
            onSubmit={(values) => {
              const { firstname, lastname, email } = values

              setLoading(true)

              const query = JSON.stringify({
                query: `
                  mutation (
                      $first_name: String!
                      $last_name: String!
                      $email: String!
                      $locale: String!
                      $petlover: Boolean!
                      $pet: String!
                  ) {
                    createSiteCustomer(
                      first_name: $first_name
                      last_name: $last_name
                      email: $email
                      locale: $locale
                      petlover: $petlover
                      pet: $pet
                    ) {
                      uuid
                    }
                  }
                `,
                variables: {
                  first_name: firstname,
                  last_name: lastname,
                  email,
                  locale: locale,
                  petlover:true,
                  pet:animal
                }
              });

              fetch(graphQlServer, {
                headers: {'content-type': 'application/json'},
                method: 'POST',
                body: query
              })
                .then(res => res.json())
                .then(() => {
                  sendEvent({
                    ga: {
                      category: 'Email_Subscribe',
                      action: 'Click',
                      label: (locale === 'en') ? 'Pets_Email_Signup' : 'Pets_Email_Signup_FR'
                    }
                  })
                  setSubmitted(true)
                })
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit
            }) => (
              <form onSubmit={handleSubmit}>
                <FormattedMessage id='petsmail.question' /><br />
                <FooterFormWrapper>
                  <div className="long">
                    <Label htmlFor="animal"
                      className={animal == 'dog' ? 'border':''}
                      onClick={() => setAnimal('dog')}>
                      <input
                        type="radio"
                        name="animal"
                        value={animal}
                        onChange={() => {
                          handleChange
                          setAnimal('dog')
                        }}
                        onBlur={handleBlur}
                        id="aninal"
                        required
                        checked={animal == 'dog' ? true:false}
                      />
                      <FormattedMessage id='petsmail.dog' />
                    </Label>
                  </div>
                  <div className="long">
                    <Label htmlFor="animal"
                      className={animal == 'cat' ? 'border':''}
                      onClick={() => setAnimal('cat')}>
                      <input
                        type="radio"
                        name="animal"
                        value={animal}
                        onChange={() => {
                          handleChange
                          setAnimal('cat')
                        }}
                        onBlur={handleBlur}
                        id="aninal"
                        required
                        checked={animal == 'cat' ? true:false}
                      />
                      <FormattedMessage id='petsmail.cat' />
                    </Label>
                  </div>
                  <div className="long">
                    <Label htmlFor="animal"
                      className={animal == 'both' ? 'border':''}
                      onClick={() => setAnimal('both')}>
                      <input
                        type="radio"
                        name="animal"
                        value={animal}
                        onChange={() => {
                          handleChange
                          setAnimal('both')
                        }}
                        onBlur={handleBlur}
                        id="aninal"
                        required
                        checked={animal == 'both' ? true:false}
                      />
                    <FormattedMessage id='petsmail.both' />
                    </Label>
                  </div>
                  <div>
                    <Label htmlFor="firstname">
                      <FormattedMessage id='footer.first_name' />
                      <input
                        type="text"
                        maxLength="256"
                        name="firstname"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstname}
                        id="firstname"
                        required
                      />
                    </Label>
                  </div>
                  <div>
                    <Label htmlFor="lastname">
                      <FormattedMessage id='footer.last_name' />
                      <input
                        type="text"
                        maxLength="256"
                        name="lastname"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastname}
                        id="lastname"
                        required
                      />
                    </Label>
                  </div>
                  <div>
                    <Label htmlFor="email">
                      <FormattedMessage id='footer.email_address' />
                      <input
                        type="email"
                        maxLength="256"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        id="email"
                        required
                      />
                    </Label>
                  </div>
                </FooterFormWrapper>

                <FooterFormCheckbox
                  type="checkbox"
                  id="checkbox"
                  name="checkbox"
                  required
                />
                <FooterFormCheckboxLabel
                  htmlFor="checkbox"
                >
                <FormattedMessage id='footer.optin'>
                  {(txt) => (
                    <span dangerouslySetInnerHTML={{__html:txt}} />
                  )}
                </FormattedMessage>
                </FooterFormCheckboxLabel>
                <FormButtonWrapper>
                  <Button type="submit" loading={loading}>
                    <FormattedMessage id='footer.join' />
                  </Button>
                </FormButtonWrapper>
              </form>
            )}
          </Formik>
      </div>
    </PetsWrapper>
  )
}


export default PetsSignUpBlock
