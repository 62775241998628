/* eslint-disable */
import React from 'react'
import Layout from '../../components/theme/Layout'
import Header from '../../components/theme/Header'
import DidYouKnow from '../../components/common/DidYouKnow'
import Vimeo from '@u-wave/react-vimeo'
import Offers from '../../components/common/Offers'
import Footer from '../../components/theme/Footer'
import RenderBlock from '../../components/blocks/RenderBlock'
import styled from '@emotion/styled'
import {StaticQuery, graphql} from 'gatsby'
import Slider from "react-slick";
import "../../contest/css/slick.css";
import "../../contest/css/slick-theme.css";

import BannerImg from '../../assets/pets-images/banner@2x.png'
import LogoImg from '../../assets/pets-images/logo_pets@2x_fr.png'

import Cleo from '../../assets/pets-images/cleo1@2x.png'
import Beng from '../../assets/pets-images/beng1@2x.png'
import Chaitea from '../../assets/pets-images/chai1@2x.jpg'
import Percy from '../../assets/pets-images/percy1@2x.jpg'
import Dorris from '../../assets/pets-images/doris.png'
import William from '../../assets/pets-images/william1@2x.png'
import Partner1 from '../../assets/pets-images/partner1@2x.png'
import Partner2 from '../../assets/pets-images/partner2@2x.png'
import Partner3 from '../../assets/pets-images/partner5.png'
import Partner4 from '../../assets/pets-images/partner4@2x.png'
import Partner5 from '../../assets/pets-images/logo_1_resized.png'
import Partner6 from '../../assets/pets-images/logo_2_resized.png'
import Partner7 from '../../assets/pets-images/logo_3_resized.png'
import Partner8 from '../../assets/pets-images/logo_4_resized.png'
import Partner9 from '../../assets/pets-images/logo_5_resized.png'
import Partner10 from '../../assets/pets-images/logo_6_resized.png'
import Partner11 from '../../assets/pets-images/logo_7_resized.png'
import Partner12 from '../../assets/pets-images/logo_8_resized.png'
import Partner13 from '../../assets/pets-images/logo_9_resized.png'
import Partner14 from '../../assets/pets-images/logo_10_resized.png'

import Contrib1 from '../../assets/images/contrib1.jpg'
import Contrib2 from '../../assets/images/contrib2.jpg'
import Contrib3 from '../../assets/images/contrib3.jpg'

import Fb from '../../assets/pets-images/fb@2x.png'
import Insta from '../../assets/pets-images/insta@2x.png'

import VideoBlock from '../../components/blocks/VideoBlock'
import {ImageLeftTextRightBlock} from '../../components/blocks/ImageLeftTextRightBlock'
import {Box} from "../../components/common/Grid";
import sendEvent from "../../helpers/sendAnalytics";
import EventTracker from "../../components/common/EventTracker";

import PetsSignUpBlock from '../../components/blocks/PetsSignUpBlock'

import { detect } from 'detect-browser'

const browser = detect();

var petsArray = [

  {
    name: 'Percy',
    text: 'Les « sœurs » de Percy voulaient un chaton. Mais lorsqu’un vieux minou noir qui n’avait plus qu’un œil est venu quémander des câlins, elles ont su immédiatement qu’il ferait partie de la famille. ',
    image: Percy,
    video_url: 'https://vimeo.com/409840219',
    title: 'Le chat à un seul œil aussi mignon qu’un chaton'
  },
  {
    name: 'Ben-G',
    text: 'Ben-G avait été placé en famille d’accueil, mais ses parents temporaires n’ont pas pu le laisser partir. Il partage maintenant son foyer avec d’autres animaux recueillis temporairement qui viennent leur rendre visite.',
    image: Beng,
    video_url: 'https://vimeo.com/409834012',
    title: 'Un chien trop adorable'
  },
  {
    name: 'Doris',
    text: 'Doris régnait sur toute la maisonnée à son arrivée, mais quand ses parents ont ramené à la maison un autre chat et un chien, elle a appris que plus il y a de la compagnie autour, plus il y a de l’amour! ',
    image: Dorris,
    video_url: 'https://vimeo.com/409835968',
    title: 'La chatte qui a appris à s’entendre avec chien et chat'
  },
  {
    name: 'Cleo',
    text: 'Cleo prend la tête du clan lors de ses promenades quotidiennes; tout le monde la suit. Elle a un véritable effet rassembleur sur cette grande famille!  ',
    image: Cleo,
    video_url: 'https://vimeo.com/409835001',
    title: 'Un petit chien bien entouré'
  },
  {
    name: 'Chai Tea',
    text: 'L’histoire de Chai Tea est indissociable de celle de Boba, deux chats adoptés ensemble qui passent maintenant leurs jours à répandre la joie et à alimenter le mur de photos de leurs propriétaires!  ',
    image: Chaitea,
    video_url: 'https://vimeo.com/409834392',
    title: 'Le chat devenu vedette des médias sociaux'
  },
  {
    name: 'William',
    text: 'William était un chien errant avant d’être rescapé par le refuge où ses parents adoptifs ont tout de suite craqué pour lui. Aujourd’hui, il est un membre de la famille heureux et en santé. ',
    image: William,
    video_url: 'https://vimeo.com/409840528',
    title: 'Le chien qui a transformé un couple en famille'
  }
]

const HeroWrapper = styled.div`
    width: 100%;
    height: auto;
    background-image: url(${BannerImg});
    background-size: 100%;
    background-repeat: no-repeat;
    padding-top: 30vh;
    @media(max-width:1150px){
        padding-top: 10vh;
    }
    @media(max-width:960px){
        padding-top: 0vh;
    }
`

const VideoBlockWrapper = styled.div`
  & > div > iframe {
    width: 100%;
  }
`

const TextContainer = styled.div`
    width: 100%;
    display: flex;
    align-items:center;
    margin-bottom: 5%;
    @media(max-width:960px){
        flex-direction: column;
    }
`

const LogoContainer = styled.img`
    width: 30%;
    @media(max-width:960px){
        width: 50%;
        margin: 100px 25% 0;
    }
`
const TextDetail = styled.div`
    width: 80%;
    max-width: 1000px;
    padding: 0% 5% 0 10px;
    color: white;
    font-family: 'Montserrat',sans-serif;
    line-height: 26pt;
    > h2 {
        font-family: 'Montserrat',sans-serif;
        font-weight: 600;
    }
    @media(max-width:960px){
        width: 100%;
        color: black;
        h2 {
            color: #2e3192;
            font-weight: bold;
        }
    }
`
const MainWrapper = styled.div`
    display: block;
    width: 90%;
    margin: 0 5%;
    @media(max-width:960px){
        width: 96%;
        margin: 0 2%;
    }
`
const PadWrapper = styled.div`
    display: block;
    width: 90%;
    margin: 70px 5%;
    text-align: center;
    font-family: 'Montserrat',sans-serif;
    @media(max-width:960px){
        width: 96%;
        margin: 50px 2% 0;
    }
`
const VideosContainer = styled.div`
    width: 100%;
    display: flex;
    height: 100%;
    @media(max-width:960px){
        flex-direction: column;
    }
`
const LargeVideo = styled.div`
    width: 85.5%;
    position: relative;
    @media(max-width:960px){
        width: 100%;
    }
`

const VideoDetails = styled.div`
    position: absolute;
    bottom: 15%;
    left: 20px;
    width: 60%;
    font-family: 'Montserrat',sans-serif;
    color: white;
    line-height: 20pt;
    padding: 15px;
    z-index: 10;
    border-radius: 10px;
    > h6 {
        margin: 0;
        font-size: 18pt;
    }
    > h3 {
        margin: 0;
        font-size: 22pt;
    }
    > span {
        width: 40px;
        transition: 0.2s;
        height:3px;
        display:block;
        background:#ABE0FA;
        border-radius: 5px;
        margin: 10px 0 25px;

    }
    > div {
        text-transform: uppercase;
        font-weight: bold;
        display: flex;
        align-items: center;
        img {
            display: inline-block;
            margin: 0 0 0 10px;
        }
    }
    @media(max-width:1150px){
      bottom: 15%;
      left: 40px;
    }
    @media(max-width:850px){
        position:relative;
        width: 100%;
        left: auto;
        bottom: auto;
    }
`

const BlackOverlay = styled.div`
    background: black;
    opacity: 0.4;
    width: 100%;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    height: 200px;
    z-index:-1;
    border-radius: 10px;
    height: 100%;
    @media(max-width:1150px){
      opacity: 0.6;
      background-color: transparent;
      background-image:linear-gradient(black,transparent);
    }
    @media(max-width:960px){
        background: #0b2867;
        opacity: 0.6;
        margin-bottom: 15px;
    }
`


const SmallVideos = styled.div`
    width: 14.5%;
    display: flex;
    flex-direction: column;
    align-content:space-around;
    justify-content:space-around;
    @media(max-width:960px){
        width: 100%;
        flex-direction: row;
    }
    @media(max-width:800px){
        width: 100%;
        display: block;
    }
`
const PetVideo = styled.div`
    cursor:pointer;
    display: flex;
    flex-direction: column;
    justify-content:center;
    margin: 0 0 5px 10px;
    transition: 0.2s;
    background-image: url(${props => props.backgroundImage});
    height: 18.5%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    :hover {
        margin: 0 0 5px 20px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        span {
            width: 40px;
        }
    }
    p {
        font-size: 15pt;
        color: white;
        font-family: 'Montserrat',sans-serif;
        text-transform: uppercase;
        margin: 0;
        padding: 0 0 5px 0;
    }
    @media(max-width:960px){
        width: 100%;
        height: 150px;
        margin: 5px;
        padding: 0;
        border-radius: 5px;
        :hover {
            margin: 5px 0;
        }
    }
    @media(max-width:800px){
        width: 100%;
        height: 150px;
        margin: 5px 0;
        padding: 0;
        border-radius: 5px;
        :hover {
            margin: 5px 0;
        }
    }
`

const ThumbText = styled.div`
    text-align: right;
    margin-top: 10px;
    margin-right: 10%;
    width: 90%;
    > span {
      width: 0px;
      transition: 0.2s;
      height:3px;
      display:block;
      background:#ABE0FA;
      margin-left: auto;
      border-radius: 5px;
    }
    @media(max-width:960px){
        margin-top: 50px;
    }
`

const Flex = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 10%;
    opacity: 1;
    align-items: center;
    justify-content: space-around;
    a img {
      max-width: 160px;
      width: auto !important;
      height: auto !important;
      margin: 0 auto 50px;
      display: block;
      opacity: 0.6;
    }
    @media(max-width:960px){
      width: 100%;
      margin: 0 0 50px;
      text-align: center;
        a {
          display: block;
          width: 50% !important;
        }
        img {
          width: 100%;
          margin: 40px auto;
          display: block;
        }
    }
`

const WhiteHeader = styled.h2`
  color: white !important;
  position: absolute;
  font-family: 'Montserrat',sans-serif;
  margin-left: 30px;
  font-weight: 500;
  font-size: 25pt;
  top: 0px;
  text-shadow: 1px 1px 3px black;
  @media(max-width:1150px){
      top: 40px;
      font-size: 20pt;
      margin-left: 40px;
  }
  @media(max-width:850px){
      top: 0px;
      font-size: 20pt;
      margin-left: 10px;
  }
`

const ShelterText = styled.p`
  font-family: 'Montserrat',sans-serif;
  line-height: 20pt;
  display: block;
  margin: 0 auto;
  max-width: 700px;
`

const Contribution = styled.img`
  flex: 1;
  opacity: 1 !important;
  @media(max-width:960px){
    width: 100% !important;
    max-width: 100% !important;
    margin: 5px 0 !important;
  }
`

const Spacer = styled.div`
    display: block;
    width: 100%;
    height: 75px;
`

const SliderFlex = styled(Slider)`
  .slick-slide {
    height: 190px;
    > div {
      height: 190px;
      display: flex;
      flex-direction: row;
      height: 100%;
      align-items: center;
    }
  }
  img {
    width: 160px;
    height: auto;
    margin: auto;
  }
  .smaller-pic {
    width: 100px;
    height: auto;
  }
`


class PetsPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      videoSize: false,
      currentVideo: petsArray[5],
      showDescription: true,
      isIE:false
    }

    this.quarterPassed = [];
    this.halfPassed = [];
    this.threeQuarterPassed = [];
    this.watched = [];

    this.onTimeUpdate.bind(this);
    this.pause = this.pause.bind(this);
  }

  pause() {
    this.slider.slickPause();
  }

  componentDidMount() {
    let newSize = 595
    if (window.innerWidth > 1366) {
      newSize = window.innerWidth * 42.8 / 100
    }
    if (window.innerWidth < 960 && window.innerWidth > 800) {
      newSize = 450
    }
    if (window.innerWidth < 800) {
      newSize = 250
    }
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get('pet');
    if (param === 'cats') {
      this.setState({currentVideo: petsArray[0]})
      this.orderByCats()
    } else if (param === 'dogs') {
      this.setState({currentVideo: petsArray[1]})
      this.orderByDogs()
    } else {
      if(param){
        let newArray = []
        for (var i = 0; i < petsArray.length; i++) {
          if(param == petsArray[i].name.toLowerCase().replace(/\s/g, '')){
            this.setState({currentVideo:petsArray[i]})
          } else {
            newArray.push(petsArray[i])
          }
        }
        petsArray = newArray
      }
    }
    this.setState({videoSize: newSize})

    var dots = document.getElementsByClassName('slick-dots')[0]
    dots.addEventListener('click',this.pause)
  }

  orderByDogs() {
    let newArray = []
    newArray[5] = petsArray[1]
    newArray[0] = petsArray[5]
    newArray[1] = petsArray[3]
    newArray[2] = petsArray[0]
    newArray[3] = petsArray[2]
    newArray[4] = petsArray[4]
    petsArray = newArray
  }

  orderByCats() {
    let newArray = []
    newArray[5] = petsArray[0]
    newArray[0] = petsArray[2]
    newArray[1] = petsArray[4]
    newArray[2] = petsArray[1]
    newArray[3] = petsArray[5]
    newArray[4] = petsArray[3]
    petsArray = newArray
  }

  getVideoId(url) {
    const matches = url.match(/vimeo.com\/(\d+)/);
    return matches[1];
  }

  onTimeUpdate(event, provider, name, videoId) {
    if (provider === 'vimeo') {
      if (event.percent > 0.25 && event.percent < .5 && !this.quarterPassed.includes(videoId)) {
        sendEvent({
          ga: {
            category: 'Engagement',
            label: 'Video_Progress',
            action: `Video_25%_${name}`
          }
        });

        this.quarterPassed.push(videoId);
      }

      if (event.percent > 0.5 && event.percent < .75 && !this.halfPassed.includes(videoId)) {
        sendEvent({
          ga: {
            category: 'Engagement',
            label: 'Video_Progress',
            action: `Video_50%_${name}`
          }
        });

        this.halfPassed.push(videoId);
      }

      if (event.percent > 0.75 && event.percent < 1 && !this.threeQuarterPassed.includes(videoId)) {
        sendEvent({
          ga: {
            category: 'Engagement',
            label: 'Video_Progress',
            action: `Video_75%_${name}`
          }
        });

        this.threeQuarterPassed.push(videoId);
      }

      if (event.percent === 1 && !this.watched.includes(videoId)) {
        sendEvent({
          ga: {
            category: 'Engagement',
            label: 'Video_Progress',
            action: `Video_100%_${name}`
          }
        });

        this.watched.push(videoId);
      }
    }
  }

  render() {

    var blocks = [this.props.data.startPage.blocks[2]]
    var rhs = this.props.data.prismic

    blocks[0].right[0].text = '<p class="p1"><span class="s1">Découvrez comment les produits ROYALE peuvent vous aider, vous et vos animaux de compagnie, grâce à ces trucs et conseils astucieux. </span></p>'
    blocks[0].right[1].link = '/fr/solutions-maison?category=W-OM_xIAACkAAzcq'

    var settings = {
      dots: true,
      autoplay:true,
      autoplaySpeed: 5000,
      infinite: true,
      speed: 1000,
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows: false,
      pauseOnFocus: true,
      pauseOnHover: true,
      pauseOnDotsHover:true,
      responsive: [
        {
          breakpoint: 919,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots:false
          }
        }
      ]
    }

    const pets = petsArray.map((pet, index) => {
      return (
        index < 5 ?
          <PetVideo
            key={index}
            backgroundImage={pet.image}
            onClick={() => {
              let petHolder = this.state.currentVideo
              petsArray[index] = petHolder
              this.setState({currentVideo: pet})
              window.scrollTo({
                top: (window.pageYOffset + document.getElementById('videos').getBoundingClientRect().top) - 10,
                left: 0,
                behavior: 'smooth'
              })
            }}
          >
            <ThumbText>
              <p>{pet.name}</p>
              <span></span>
            </ThumbText>
          </PetVideo>
          : null
      )
    })
    return (
      <Layout
        meta={{
          title: 'Projet un abri pour chaque ami | ROYALE®'
        }}
        urls={{
          'en': `/pets`,
          'fr': `/fr/ami`
        }}
        locale='fr'
      >
        <Header
          urls={{
            'en': `/pets`,
            'fr': `/fr/ami`
          }}
          locale='fr'
        />
        <HeroWrapper>
          <MainWrapper>
            <TextContainer>
              <LogoContainer src={LogoImg}/>
              <TextDetail>
                <h2>Un animal de compagnie peut apporter une présence, de la joie et du réconfort, mais lorsqu’un animal
                  provenant d’un refuge trouve un foyer permanent, il se crée un lien unique. </h2>
                <p><b>Bien des familles ne peuvent s’imaginer vivre sans leurs compagnons à quatre pattes, surtout
                  durant les périodes d’incertitude et de stress.</b> Offrir à l’un des plus de 100 000 &nbsp;chiens et
                  chats issus de refuges canadiens un foyer permanent procure un grand sentiment de joie. Étant la
                  Marque des Chatons<sup>MD</sup>, ROYALE<sup>MD</sup> croit que tout le monde mérite de connaître cette
                  joie. C’est pourquoi nous avons mis sur pied le <b>Projet un abri pour chaque ami de
                    ROYALE<sup>MD</sup></b>, afin de sensibiliser la population et de recueillir des fonds qui serviront
                  à financer les soins essentiels donnés dans ces refuges et trouver à ces animaux une famille aimante.
                  Prenez connaissance des belles histoires d’adoption ci-dessous. </p>
              </TextDetail>
            </TextContainer>
          </MainWrapper>
          <MainWrapper>
            <VideosContainer id="videos">

              <LargeVideo>
                <WhiteHeader>La joie de l’adoption</WhiteHeader>
                {this.state.videoSize ?
                  <VideoBlockWrapper>
                    <Vimeo
                      video={this.getVideoId(this.state.currentVideo.video_url)}
                      height={this.state.videoSize}
                      onPlay={() => {
                        this.setState({showDescription: false})
                      }}
                      onPause={() => {
                        this.setState({showDescription: true})
                      }}
                      onTimeUpdate={(e) => this.onTimeUpdate(e, 'vimeo', this.state.currentVideo.name, this.getVideoId(this.state.currentVideo.video_url))}
                      key={this.state.currentVideo.video_url}
                    />
                  </VideoBlockWrapper>
                  :
                  null
                }
                {this.state.showDescription ?
                  <VideoDetails>
                    <h6>{this.state.currentVideo.name}</h6>
                    <span></span>
                    <h3>{this.state.currentVideo.title}</h3>
                    <p>{this.state.currentVideo.text}</p>
                    <div>
                      PARTAGER SUR :
                      <a
                        href={"https://www.facebook.com/sharer/sharer.php?u=" + this.state.currentVideo.video_url}
                        onClick={() =>
                          sendEvent({
                            ga:{
                              category:'Engagement',
                              action:'Share',
                              label: 'Facebook_Share_' + this.state.currentVideo.name
                            }
                          })
                        }
                        target="_blank"
                      ><img src={Fb}/></a>
                      {/* <a href={"https://www.instagram.com/?url=" + this.state.currentVideo.video_url} target="_blank"><img src={Insta} /></a> */}
                    </div>
                    <BlackOverlay/>
                  </VideoDetails> : null
                }
              </LargeVideo>
              <SmallVideos>
                {pets}
              </SmallVideos>
            </VideosContainer>
            <small style={{
              'text-align': 'center',
              width: '100%',
              margin: '0 auto',
              display: 'block',
              'font-family':
                'Montserrat'
            }}>Tous les segments ont été filmés avant la mise en place des protocoles de distanciation sociale. </small>
          </MainWrapper>
        </HeroWrapper>
        <PadWrapper>
          <h2>Soutien aux refuges</h2>
          <ShelterText>En 2019, nous avons identifié quatre principaux refuges partenaires dans diverses régions du
            Canada afin de mettre sur pied le <b>Projet un abri pour chaque ami</b>. Nous leur offrons des produits
            ROYALE<sup>MD</sup> et les aidons à financer les améliorations à qu’ils désirent apporter à leurs
            installations.
            <br/><br/>
            Nous présentons maintenant officiellement le programme au public en plus de verser un don de 50 000 $ qui
            sera réparti entre les partenaires actuels et à venir.
            <br/><br/>
            Nos activités de sensibilisation et de financement se poursuivront à mesure que le programme prend de
            l’expansion d’un bout à l’autre du pays. </ShelterText>
          <br/><br/>
          <h2>Nos refuges partenaires</h2>
          {/* this.state.videoSize ?
                    <VideoBlock url={this.state.currentVideo.video_url} height={this.state.videoSize > 600 ? 600 : this.state.videoSize } />
                    :
                    null
                 */}
          <Spacer/>
          <SliderFlex ref={slider => (this.slider = slider)} {...settings}>
            <a href="https://www.torontohumanesociety.com/ " target="_blank"><img src={Partner1}/></a>
            <a href="https://www.monctonspca.ca/en " target="_blank"><img src={Partner2}/></a>
            <a href="https://spca.bc.ca/" target="_blank"><img src={Partner3}/></a>
            <a href="https://www.spca.com/en/" target="_blank"><img className="smaller-pic" src={Partner4}/></a>
            <a href="https://www.calgaryhumane.ca/" target="_blank"><img src={Partner5}/></a>
            <a href="https://www.edmontonhumanesociety.com/" target="_blank"><img src={Partner6}/></a>
            <a href="https://www.novascotiaspca.ca/" target="_blank"><img src={Partner7}/></a>
            <a href="https://spcastjohns.org/" target="_blank"><img src={Partner8}/></a>
            <a href="https://ottawahumane.ca/" target="_blank"><img src={Partner9}/></a>
            <a href="https://www.peihumanesociety.com/" target="_blank"><img src={Partner10}/></a>
            <a href="https://spadequebec.ca/" target="_blank"><img src={Partner11}/></a>
            <a href="https://reginahumanesociety.ca/" target="_blank"><img src={Partner12}/></a>
            <a href="http://www.spcaanimalrescue.com/" target="_blank"><img src={Partner13}/></a>
            <a href="https://www.winnipeghumanesociety.ca/" target="_blank"><img src={Partner14}/></a>
          </SliderFlex>
          <Flex flexWrap="wrap" mx={[0, 0, 0, -3]} style={{'margin-bottom': '30px'}}>
            <Box width={[1, 1, 1, 1 / 3]} px={[0, 0, 0, 3]}>
              <Contribution src={Contrib1}/>
            </Box>
            <Box width={[1, 1, 1, 1 / 3]} px={[0, 0, 0, 3]}>
              <Contribution src={Contrib2}/>
            </Box>
            <Box width={[1, 1, 1, 1 / 3]} px={[0, 0, 0, 3]}>
              <Contribution src={Contrib3}/>
            </Box>
          </Flex>
          <small style={{
            'text-align': 'center',
            width: '100%',
            margin: '0 auto',
            display: 'block',
            'font-family':
              'Montserrat'
          }}>Tous les segments ont été photographiés avant la mise en place des protocoles de distanciation
            sociale. </small>

        </PadWrapper>
        <RenderBlock blocks={blocks}/>
        <DidYouKnow blogPosts={rhs} locale="fr"/>

        <PetsSignUpBlock locale="fr" />

        <h4 className="lasth4">
          Vous désirez en savoir plus sur le Projet un abri pour chaque ami de ROYALE<sup>MD</sup>? Consultez notre
          FAQ <a href="../fr/foire-aux-questions/#ami">ICI</a>.</h4>
          { browser.name == 'ie' ?
            '':<EventTracker gtag={{'allow_custom_scripts': true,'send_to': 'DC-8521957/invmedia/tiger0+standard'}} />
          }
        }
        <Footer hideSignUp locale="fr"/>
        <Offers />
      </Layout>
    )
  }
}

export const query = graphql`
query {
  startPage(guid: { eq: "54319571-6668-471c-bc49-71b26b4b0ddf" }, locale: { eq: "fr" }) {
    title_html
    blocks {
      __typename
      ...productCategoriesBlockFragment
      ...imageLeftTextRightBlockFragment
      ...promotionBlockFragment
    }
  }
  prismic {
    blog_1: home_solution_article(uid: "tenir-les-oreilles-de-nos-petites-boules-de-poils-bien-propres", lang: "fr-ca") {
      title
      preview_image
      _meta {
        id
        uid
      }
    }
    blog_2: home_solution_article(uid: "a-bas-les-pattes-sales", lang: "fr-ca") {
      title
      preview_image
      _meta {
        id
        uid
      }
    }
    blog_3: home_solution_article(uid: "un-irresistible-jouet-pour-minous", lang: "fr-ca") {
      title
      preview_image
      _meta {
        id
        uid
      }
    }
  }
}
`

export default PetsPage
